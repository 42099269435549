<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import {
    required,
} from "vuelidate/lib/validators";

import {getPMApi} from '@/api/pm'
import {search_agent_landlord} from '@/api/misc'


/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {
        agent_name : {required},
        property_address : {required},
        landlord_name : {required},
        owner_ship : {required},
        pma_prefix_code    : {required},
        pma_suffix_code    : {required},
  },


  methods: {

    formSubmit() {

      let total_owner_ship = this.landlords.reduce((a, c)=>a += Number(c.owner_ship), 0)
     
     
      let landlord_check = true 
      if (total_owner_ship != 100) {
        this.owner_ship = ''
      } else {
        this.owner_ship = 100
      }

     

      for (let ll of this.landlords) {
        if (!ll.id) {
          landlord_check = false
          //mark error notification
          this.landlord_name = ''
          break
        }
      }

      //skip validator
      if (landlord_check) {
        this.landlord_name = 'Valid'
      }
      
      this.$v.$touch();
      if (this.$v.$invalid == false && landlord_check == true) {

        let landlord_property = {
          agent_id : this.agent_id,
          landlords : this.landlords,
          property : {
            address: this.property_address,
            apt    : this.unit
          },
          pma_code : this.pma_prefix_code+this.pma_suffix_code
        }

        getPMApi().landlord_add_property(landlord_property).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Property is Setup" );
            this.$router.push({name : 'pm-property-list'})
          } else {
            this.$alertify.error("Setup Property failed:" + res.errCode );
          }
        })
      }

    },


    onSelectedAgent(agent) {
      this.agent_id = agent.agent.id
      this.agent_name = agent.agent.first_name + ' '+agent.agent.last_name
      this.pma_prefix_code = agent.agent.property_mgt_code
    },

    onLandlordSelected(evt, idx) {
      this.landlords[idx].name = evt.landlord_name
      this.landlords[idx].id = evt.id
    },

    onPMAddressSelected(evt) {
      this.property_address = evt.full
    },

    queryLandlord(str) {
      if (this.agent_id) {
        search_agent_landlord(str, this.agent_id).then(res => {
          if (res.errCode == 0) {
            this.landlords_data = []
            res.data.map(e => {
              e.label = e.landlord_name +"-"+ e.code
              this.landlords_data.push(e)
            })
          }
        })
      }
    },

    add_landlord_item() {
      this.landlords.push({
        id        : '',
        name      : '',
        owner_ship: 0,
      })
    },

    remove_landlord(idx) {
      this.landlords.splice(idx, 1)
    },
   
  },

  data() {
    return {
      title: "PM",
      items: [
      {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: "Setup New Property",
          active: true,
        },
      ],



      agent_id               : '',
      agent_name             : '',
      landlord_name          : '',
      pma_prefix_code        : '',
      pma_suffix_code        : '',
      searched_landlord_name : '',
      owner_ship             : '',
      unit                   : '',
      property_address       : '',
      landlords_data         : [],
      landlords              : [],
    };
  },
  components: {
    Layout,
    PageHeader,
    AgentAutoComplete,
    AddressAutoComplete,
    VueBootstrapTypeahead
    
  },
  mounted() {

  },

  watch: {


    searched_landlord_name (new_search_name) {
      this.queryLandlord(new_search_name)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Basic Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" >Agent</label>
                          <AgentAutoComplete
                                    v-model="agent_name"
                                    @onSelected="onSelectedAgent"
                                    autocomplete="off"
                                    :init_data="{style_class : {'is-invalid':$v.agent_name.$error}, agent_name : agent_name}"
                                />
                          <div v-if="$v.agent_name.$error" class="invalid-feedback">
                              <span v-if="!$v.agent_name.required">This value is required.</span>
                          </div>

                        </div>
                      </div>


                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" > PMA Prefix Code</label>
                          <input class="form-control" v-model="pma_prefix_code" readonly   :class="{'is-invalid':$v.pma_prefix_code.$error}"/>
                          <div v-if="$v.pma_prefix_code.$error" class="invalid-feedback">
                              <span v-if="!$v.pma_prefix_code.required">This value is required.</span>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" > PMA Suffix Code</label>
                          <input class="form-control" v-model="pma_suffix_code" :class="{'is-invalid':$v.pma_suffix_code.$error}"/>
                          <div v-if="$v.pma_suffix_code.$error" class="invalid-feedback">
                              <span v-if="!$v.pma_suffix_code.required">This value is required.</span>
                          </div>

                        </div>
                      </div>

                      
                    </div>

                    <div class="row">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label >Unit</label>
                          <input class="form-control" v-model="unit" />
                        </div>
                      </div>
                      <div class="col-lg-9">
                        <div class="mb-3">
                          <label >Property Address</label>
                          <AddressAutoComplete
                              v-model="property_address"
                              :init_data="{style_class : {'is-invalid':  $v.property_address.$error }, address : property_address}"
                              @onSelected="onPMAddressSelected($event)"
                              autocomplete="off"
                              />
                          <div v-if=" $v.property_address.$error" class="invalid-feedback">
                              <span v-if="!$v.property_address.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center ">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Landlord Info</h5>
                  </div>
                  <div class="flex-grow-2 d-flex align-items-start justify-content-end  col-md-2">
                    <button  type="button" class="btn btn-primary w-sm" @click="add_landlord_item">Add Landlord</button>
                  </div>
                </div>
                <div class="card-body">

                  <div class="row" v-for="(landlord,idx) in landlords" :key="'landlord_'+idx">
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Landlord</label>
                        <vue-bootstrap-typeahead 
                            v-model="searched_landlord_name"
                            :value ="landlord.name"
                            :data="landlords_data"
                            :inputClass=" {'is-invalid':$v.landlord_name.$error}"
                            :serializer="s => s.label"
                            :foramterDisplay="s => s.label"
                            @hit="onLandlordSelected($event, idx)"
                            autocomplete="off"
                            />
                      
                        <div v-if="$v.landlord_name.$error" class="invalid-feedback">
                            <span v-if="!$v.landlord_name.required">This value is required.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">OwnerShip</label>
                        <input type="text" class="form-control" v-model="landlord.owner_ship" :class="{'is-invalid':$v.owner_ship.$error}"/>
                        <div v-if="$v.owner_ship.$error" class="invalid-feedback">
                            <span v-if="!$v.owner_ship.required">This value required and total is 100%</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2  d-flex align-items-start justify-content-end ">
                      <div class="mt-4">
                      <b-button variant="outline-danger" size="sm" @click="remove_landlord(idx)" >
                        <i class="mdi mdi-close"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <!-- end row -->
                </div>
                <!-- end card body-->
              </div>
              <!-- end card-->

              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                    <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name: 'pm-property-list'})"> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm">
                      Add PMA
                    </button>
                  </div>
                </div>
              </div>

            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
